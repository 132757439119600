import Header from "./Header";
import Footer from "./footer";
import ScrollToTop from "./scrollTop";

export default function Privacy() {
  const stylePara =
    "lg:w-full text-center lg:text-left default-text-color mt-[15px] text-[14px] leading-[34px] font-medium";
  const head =
    "title-text-color  text-center lg:text-left text-[20px] font-bold leading-[50px] mt-[10px]";

  return (
    <>
      <ScrollToTop />

      <div className="bg-[#fff]">
        <div>
          <Header />
        </div>
        <div className="max-w-[100%] mx-auto pt-[180px] lg:px-16 px-16" id="">
          <div>
            <main className="fs-main">
              <h3 className={head}>Privacy Policy</h3>
              <p className={stylePara}>
                At D-Man, we value the trust you place in us and recognize the
                importance of privacy of our visitors. This Privacy Policy
                document contains types of information that is collected and
                recorded by D-Man through https://www.dman.in/, its mobile
                application, and m-site.{" "}
              </p>
              <p className={stylePara}>
                By visiting the Platform, providing your information or availing
                our product/service, you expressly agree to be bound by the
                terms and conditions of this Privacy Policy, the Terms of Use
                and the applicable service/product terms and conditions. If you
                do not agree, please do not access or use our Platform. If you
                have any additional queries or require more information about
                our Privacy Policy, feel free to reach us.
              </p>
              <p className={stylePara}>
                If you have additional questions or require more information
                about our Privacy Policy, do not hesitate to contact us.
              </p>
              <h3 className={head}>
                Collection of Information from Customer's Mobile Application
              </h3>
              <p className={stylePara}>
                When you use our platform, we collect and store your information
                which is provided by you from time to time. The following
                information includes the user's name, email id and mobile number
                of the respective user. We use your mobile number for the login
                authentication. As we use your name, email id just for the
                reference and we won't share these details with any third party
                applications. We will share these information only with the
                merchant application and delivery partner application for
                processing orders.
              </p>
              <h3 className={head}>
                How we handle Location Services in Customer Mobile Application
              </h3>
              <p className={stylePara}>
                We will collect the user's current location based on the user's
                device GPS and Network Location. We may use this information to
                provide you with location-based services to show the
                availability of our services nearby the user's location. We will
                fetch the location only when users give the permission to
                access. If the user denies the permission then the application
                will not show any of our services.
              </p>
              <h3 className={head}>
                Collection of Information from Delivery Partner's Mobile
                Application
              </h3>
              <p className={stylePara}>
                We will collect the user's name, email id, mobile number,
                address, city, state and pincode while registering in Delivery
                Partner's Application. We also collect the user's ID Proof such
                as Government Authorized ID, Snap of Driving License, Snap of
                Delivery Partner and Vehicle Registration Number. This
                information is mandatory to join us as a Delivery Partner. We
                will do background verification before the Delivery Partner
                approval.
              </p>
              <p className={stylePara}>
                We will collect the above documents through our Delivery
                Partner's mobile application during registration and also we may
                collect the hard copies during the background verification.
              </p>
              <h3 className={head}>
                How we handle Location Services in Delivery Partner Application{" "}
              </h3>
              <p className={stylePara}>
                Delivery Partner's Location is the primary need in any delivery
                services. We will collect the Delivery Partner's device location
                based on the device GPS and Network Location. Our application
                will request the user with its location permission during the
                application run time before fetching the details. You can permit
                the platform to access your current location through the
                permission system used by the mobile operating system which
                collects the precise location of your device when the app is
                running in the foreground or background. Without this permission
                we will not fetch any location data from the user's Device.
              </p>
              <h3 className={head}>Why we collect Location Data</h3>
              <p className={stylePara}>
                We will be using the location data to assign the orders to
                nearby delivery partners and to track the delivery in a real
                time. We collect Delivery Partner's device Location in both
                Foreground and Background of the application. This application
                will show foreground service alert as an On-Going Service in the
                device notification area. Without this data we cannot assign the
                orders to the nearest delivery partners.
              </p>
              <p className={stylePara}>
                We also share this location data with our customer and merchant
                application for every order delivery. This will help us to track
                the Delivery Partners in real time, and the data will not be
                shared anywhere except these two platforms.
              </p>
              <h3 className={head}>Cookies</h3>
              <p className={stylePara}>
                Like any other website, Our company uses data collection devices
                such as "cookies" on certain pages of the website to help
                analyse our web page flow, measure promotional effectiveness,
                and promote trust and safety.These cookies are used to store
                information including visitors' preferences, user credentials
                and the pages on the website that the visitor accessed or
                visited. The information is used to optimize the users'
                experience by customizing our web page content based on
                visitors' browser type and/or other information.
              </p>
              <p className={stylePara}>
                For more general information on cookies, please read{" "}
                <a href="https://www.cookieconsent.com/what-are-cookies/">
                  "What Are Cookies" from Cookie Consent
                </a>
                .
              </p>
              <h3 className={head}>Advertising Partners Privacy Policies</h3>
              <p className={stylePara}>
                You may consult this list to find the Privacy Policy for each of
                the advertising partners of D-Man.
              </p>
              <p className={stylePara}>
                Third-party ad servers or ad networks uses technologies like
                cookies, JavaScript, or Web Beacons that are used in their
                respective advertisements and links that appear on D-Man, which
                are sent directly to users' browser. They automatically receive
                your IP address when this occurs. These technologies are used to
                measure the effectiveness of their advertising campaigns and/or
                to personalize the advertising content that you see on websites
                that you visit.
              </p>
              <p className={stylePara}>
                Note: D-Man has no access to or control over these cookies that
                are used by third-party advertisers.
              </p>
              <h3 className={head}>Third Party Privacy Policies</h3>
              <p className={stylePara}>
                D-Man's Privacy Policy does not apply to other advertisers or
                websites. Thus, we are advising you to consult the respective
                Privacy Policies of these third-party ad servers for more
                detailed information. It may include their practices and
                instructions on how to exclude certain options.{" "}
              </p>
              <p className={stylePara}>
                You can choose to disable cookies through your individual
                browser options. To know the detailed information about cookie
                management with respect to the web browsers, it can be found at
                the respective websites of the browser.
              </p>
              {/* <h3 className={head}>CCPA Privacy Rights (Do Not Sell My Personal Information)</h3>
    <p className={stylePara}>
      Under the CCPA, among other rights, California consumers have the
      right to:
    </p>
    <p className={stylePara}>
      Can request a business that collects a consumer's personal data must
      disclose the categories and specific pieces of personal data that
      has been collected on consumers.
    </p>
    <p className={stylePara}>
      Can request a business to delete any of the consumer's personal data
      that a business has collected.
    </p>
    <p className={stylePara}>
      Can request a business to keep the data secure and do not try to
      sell a consumer's personal data.
    </p>
    <p className={stylePara}>
      If you make any of the above requests, we will take at most one
      month to respond to you. So if you would like to exercise any of
      these above rights, please do reach us.
    </p> */}
              {/* <h3 className={head}>GDPR Data Protection Rights</h3>
    <p className={stylePara}>
      We would like to make sure you are fully aware of all the data
      protection rights. Every user is entitled to the following:
    </p>
    <p className={stylePara}>
      <b>The right to access</b> â€“ You have every right to request copies of your
      personal data. As we may charge you with a small fee for this
      service.
    </p>
    <p className={stylePara}>
      <b>The right to rectification</b> â€“ You have the right to inform if any of
      the given information you believe is inaccurate. You also have the
      right to request for completing the information that you feel is
      incomplete.
    </p>
    <p className={stylePara}>
      <b>The right to erase</b> â€“ You have the right to request for erasing your
      personal data, under certain conditions.
    </p>
    <p className={stylePara}>
      <b>The right to restrict processing</b> â€“ You have the right to request on
      restricting the processing of your personal data, under certain
      conditions.
    </p>
    <p className={stylePara}>
      <b>The right to object to processing</b> â€“ You have every right to object
      to our processing of your personal data, under certain conditions.
    </p>
    <p className={stylePara}>
      <b>The right to data portability</b> â€“ You have the right to request on
      transferring the data that we have collected to another
      organization, or directly to you, under certain conditions.
    </p>
    <p className={stylePara}>
      If you make any of the above requests, we will take at most one
      month to respond to you. So if you would like to exercise any of
      these above rights, please do reach us.
    </p> */}
              <h3 className={head}>Children's Information</h3>
              <p className={stylePara}>
                Another part of our priority is to add protection for children
                while using the internet. We encourage parents and guardians to
                observe, participate in, monitor and guide their online
                activity.
              </p>
              <p className={stylePara}>
                D-Man does not knowingly collect any Personal Identifiable
                Information from children under the age of 13. If you think that
                your child has provided this kind of information on our website,
                we strongly encourage you to contact us immediately and we will
                do our best efforts to promptly remove such information from our
                records.
              </p>
              <h3 className={head}>Delete D-Man Account</h3>
              <p className={stylePara}>
                <span> Customer : </span>As a D-Man customer, you can delete
                your account directly from the profile screen in the D-Man app.
              </p>
              <p className={stylePara}>
                <span> Merchant Partner & Delivery Partner :</span> To delete
                your merchant or delivery partner account, please reach out to
                our support team for assistance. We’d like to discuss your
                request before proceeding with the deletion.
              </p>
            </main>
            <br />
            <br />
          </div>
        </div>
        {/* <div>
  <Footer />
</div> */}
      </div>
    </>
  );
}
