import DmanLogo from "../assets/images/dmanlogo.svg";
import Dman from "../assets/images/dman.svg";
import QRCode from "../assets/images/QRcode.svg";
import AppStore from "../assets/images/appstore.svg";
import PlayStore from "../assets/images/playstore.svg";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
const Download = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/download-app") {
      getMobileOperatingSystem();
    }
  }, []);

  const getMobileOperatingSystem = () => {
    var userAgent =
      navigator && (navigator.userAgent || navigator.vendor || window.opera);
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return (window.location.href =
        "https://apps.apple.com/us/app/d-man/id6740759770");
    }
    return (window.location.href =
      "https://play.google.com/store/apps/details?id=com.dman.customer");
  };
  return (
    <div className="bg-[#F6F8FB] min-h-[100vh]">
      <div className="max-w-[100%] mx-auto pt-24 pb-24 lg:px-16">
        <div className="flex justify-center items-center ">
          <div className="bg-[#fff] rounded-[20px] py-10  max-w-[808px] max-h-[689px] p-4 lg:p-14 flex flex-col justify-center items-center gap-5">
            <div className="flex items-center gap-3">
              <div>
                <img src={DmanLogo}></img>
              </div>
              <div>
                <img src={Dman}></img>
                <p className="text-[13.263px] font-medium  mt-[8px] nav-text-color">
                  Delivering Happiness
                </p>
              </div>
            </div>
            <div className="text-[#212121] text-[24px] leading-[24px] font-semibold mt-4">
              Welcome to D-man
            </div>
            <div className="bg-[#F2F2F2] h-[1px] w-full px-5"></div>
            <div className="text-[#667085] text-[16px] leading-[30px] text-center">
              We are very grateful to have you as our customer. Please download
              our app and enjoy a intriguing and a user friendly experience.
            </div>
            <div>
              <img className="w-[200px] h-[200px]" src={QRCode}></img>
            </div>
            <div className="flex flex-col lg:flex-row gap-14 mt-5">
              <div className="cursor-pointer">
                <a
                  href="https://play.google.com/store/apps/details?id=com.dman.customer"
                  target="_blank"
                >
                  <img src={PlayStore}></img>
                </a>
              </div>
              <div className="cursor-pointer">
                <a
                  href="https://apps.apple.com/us/app/d-man/id6740759770"
                  target="_blank"
                >
                  <img src={AppStore}></img>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Download;
